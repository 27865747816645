import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';

const initialState = {
  search: '',
  results: []
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    // this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  getInitialState() {
    return initialState;
  }
  componentDidMount() {

  }
  handleChange(event) {
    this.setState({ search: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    const _this = this;
    const theSearch = this.state.search;
    this.setState({
      search: ''
    });
    fetch('/vids', {
      method: 'POST',
      body: JSON.stringify({
        search: theSearch
      }),
      headers: { "Content-Type": "application/json" }
    })
      .then(function (response) {
        return response.json()
      }).then(function (body) {
        _this.setState({
          results: body
        });
      });
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>O<span className="grey">H</span>M<span className="grey">Y</span>F<span className="grey">UCKING</span></h1>
          <form onSubmit={this.handleSubmit}>
            <input type="text" value={this.state.search} onChange={this.handleChange} />
            <input type="submit" value="SEARCH" />
          </form>
        </header>
        {this.state.results[0] ? <Grid results={this.state.results} /> : null}
      </div>
    );
  }
}

export default App;


class Grid extends Component {
  render() {
    const results = this.props.results;
    return (
      <div className="Grid">
        {results.map(vid => {
          return (
            <a className="grideo" key={vid.title} href={vid.url} target="blank" style={{ background: 'url(' + vid.thumb + ') no-repeat center center/cover' }} >
              <span className="grideo-title">{vid.title}</span>
              <span className="grideo-duration">{vid.duration}</span>
            </a>
          )
        })}
      </div>
    );
  }
}